
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { langs, snacksCategories, snacksTypes } from '@/constants';
import { Snack } from '@/entities/restaurant/Snack';
import { Text } from '@/entities/Text';
import { Image } from '@/entities/images/Image';
import { Location } from '@/entities/Location';

@Component({
  computed: mapGetters([
    'location'
  ])
})
export default class SnacksCreateView extends Vue {
  location!: Location;

  snack = {
    name: {

    } as Text,
  } as Snack

  langs = langs;
  lang = langs[0].abbr;
  
  categories = snacksCategories;
  types = snacksTypes;

  search = "";
  images: Image[] = [];

  selectedImage = 0;
  
  mounted() {
    if (this.$route.name === "snacks-edit" && this.snackId === null) {
      this.$router.replace("/snacks");
    }
    
    this.fetchImages();
    this.fetchSnack();
  }

  get snackId() {
    if (this.$route.params.snack !== undefined) {
      return Number(this.$route.params.snack);
    }

    return null;
  }

  fetchSnack() {
    if (this.snackId !== null) {
      this.$snacks.getById(this.snackId).then(snack => {
        this.snack = snack;
      });
    }
  }

  fetchImages() {
    this.$images.list().then(images => {
      this.images = images;
    });
  }

  save() {
    if (this.snackId !== null) {
      this.$snacks.edit(this.snack, this.snack);
      return;
    }

    this.snack.location = this.location.uuid;

    this.$snacks.create(this.snack).then(() => {
      this.$router.replace("/snacks");
    });
  }

  get filtered() {
    return this.$helpers.filterImages(this.images, this.search, ["Food"]);
  }
  
  get getName() {
    return this.snack.name[this.lang];
  }

  set getName(value) {
    this.snack.name[this.lang] = value;
  }
}
